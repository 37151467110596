import { type ErrorInfo, Component } from 'react'
import { isRouteErrorResponse, useRouteError } from 'react-router-dom'
import { Nullable } from 'ts-toolbelt/out/Union/Nullable'

import { AppError } from './AppError'
import { Error403 } from './Error403'
import { Error404 } from './Error404'

const Error = () => {
	const error = useRouteError() as Nullable<Error>

	if (isRouteErrorResponse(error) && error?.status === 403) return <Error403 />
	if (isRouteErrorResponse(error) && error?.status === 404) return <Error404 />

	return (
		<>
			<AppError />
		</>
	)
}

export class ErrorBoundary extends Component {
	state = {
		error: false,
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
		console.error({ error, errorInfo })
		this.setState({ error: true })
	}

	render() {
		const { children } = this.props
		const { error } = this.state

		return <>{error ? <Error /> : children}</>
	}
}
