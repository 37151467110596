import { AddressModal, AddressModalProps } from 'components/forms/Address'
import { CompanyControls, CompanyControlsProps } from 'components/forms/Company/CompanyControls'
import { FullNameModal, FullNameModalProps } from 'components/forms/FullName'
import {
	ComCreditclubPartnerCommonDtoV1AddressDto,
	ComCreditclubPartnerLkServiceSuggestionOrganizationResult,
} from 'dto'
import { useBoolean } from 'hooks/useBoolean'
import { mergeDeepRight, pick } from 'rambda'
import { useTheme } from 'styled-components'
import { getInputProps } from 'styles/theme'

import { join } from '@creditclubteam/kit/helpers'
import { Input, Text, useAddresscomplete, useAutocomplete } from '@creditclubteam/kit/ui-components'
import { Checkbox, CheckboxProps } from 'components/common/Checkbox'
import { FadeIn } from 'components/common/FadeIn'
import { Field } from 'components/common/Field'
import { Grid } from 'components/common/Grid'
import { Hint } from 'components/common/Hint'
import { utils } from 'helpers/utils'

import { useData } from './useData'

const suggestionsEndpoint = '/v1/suggestions/organization'

export const Organization = () => {
	const {
		formik: {
			values,
			touched,
			handleSubmit,
			handleBlur,
			resetForm,
			handleChange,
			errors,
			setFieldValue,
			setValues,
		},
		status,
		edit,
	} = useData()
	const theme = useTheme()
	const representativeFullNameModal = useBoolean()
	const mainContactFullNameModal = useBoolean()
	const registrationAddressModal = useBoolean()
	const mailingAddressModal = useBoolean()

	const fullNameProps = getInputProps({
		inputContainerStyleOverride: { alignItems: 'center' },
		errorMessage: errors.legalInfoFullName?.query,
		error: !!errors.legalInfoFullName && !!touched.legalInfoFullName,
		onFocus: handleBlur,
		name: 'legalInfoFullName',
		autocompleteConfig: useAutocomplete<
			ComCreditclubPartnerLkServiceSuggestionOrganizationResult[],
			ComCreditclubPartnerLkServiceSuggestionOrganizationResult
		>({
			query: values.legalInfoFullName.query,
			selected: values.legalInfoFullName.selected,
			getOptionItem: (response) => ({
				id: response.taxId!,
				label: response.fullName,
				payload: response,
			}),
			onSelect: (option) =>
				setValues((prev) =>
					mergeDeepRight(prev, {
						legalInfoFullName: {
							query: option.label as string,
							selected: option,
						},
						registrationAddress: {
							selected: option.payload?.registrationAddress ?? values.registrationAddress.selected,
							query:
								option.payload?.registrationAddress?.mergedAddress ??
								values.registrationAddress.query,
						},
						ogrn: option.payload?.ogrn ?? values.ogrn,
						taxId: option.payload?.taxId ?? values.taxId,
						kpp: option.payload?.kpp ?? values.kpp,
						shortName: option.payload?.shortName ?? values.shortName,
						representativeFullName: {
							query:
								utils.getFullName(option.payload?.representative) ??
								values.representativeFullName.query,
							selected: utils.getFullName(option.payload?.representative)
								? null
								: values.representativeFullName.selected,
							manual: utils.getFullName(option.payload?.representative)
								? {
										name: option.payload?.representative?.name ?? '',
										surname: option.payload?.representative?.surname ?? '',
										patronymic: option.payload?.representative?.patronymic ?? '',
									}
								: values.representativeFullName.manual,
						},
						representativePost: option.payload?.representative?.post ?? values.representativePost,
					})
				),
			request: {
				method: 'POST',
				url: suggestionsEndpoint,
				data: {
					query: values.legalInfoFullName.query,
				},
			},
		}),
		value: values.legalInfoFullName.query,
		onChange: ({ target }) =>
			setValues((prev) =>
				mergeDeepRight(prev, {
					legalInfoFullName: {
						query: target.value,
						selected: values.legalInfoFullName.selected ? null : values.legalInfoFullName.selected,
					},
				})
			),
		label: 'Полное наименование',
		placeholder: 'Название компании',
	})

	const taxIdProps = getInputProps({
		label: 'ИНН',
		mask: '9'.repeat(10),
		value: values.taxId,
		name: 'taxId',
		placeholder: '10 цифр',
		errorMessage: errors.taxId,
		error: 'taxId' in errors && !!touched.taxId,
		onFocus: handleBlur,
		onChange: handleChange,
	})

	const registrationAddressProps = getInputProps({
		error: !!errors.registrationAddress && !!touched.registrationAddress,
		onFocus: handleBlur,
		name: 'registrationAddress',
		label: 'Адрес регистрации юридического лица',
		placeholder: 'Город/населённый пункт, улица, корпус, строение, номер дома, офис',
		value: values.registrationAddress.query,
		onChange: ({ target }) =>
			setFieldValue('registrationAddress', {
				query: target.value,
				selected: null,
			}),
		autocompleteConfig: useAddresscomplete<
			ComCreditclubPartnerCommonDtoV1AddressDto[],
			ComCreditclubPartnerCommonDtoV1AddressDto
		>({
			onManual: registrationAddressModal.setTrue,
			request: {
				url: '/v1/suggestions/address',
				method: 'post',
				data: {
					query: values.registrationAddress.query,
				},
			},
			query: values.registrationAddress.query,
			selected: values.registrationAddress.selected,
			onSelect: (option) =>
				setFieldValue('registrationAddress', {
					query: option.label,
					selected: option,
				}),
		}),
	})

	const registrationAddressModalProps: AddressModalProps = {
		onChange: (value) =>
			setFieldValue('registrationAddress', {
				selected: {
					id: value.mergedAddress,
					label: value.mergedAddress,
					payload: value,
				},
				query: value.mergedAddress,
			}),
		isOpen: registrationAddressModal.value,
		onClose: registrationAddressModal.setFalse,
	}

	const mailingAddressProps = getInputProps({
		label: 'Почтовый адрес юридического лица',
		value: values.mailingAddress.query,
		error: !!errors.mailingAddress && !!touched.mailingAddress,
		placeholder: 'Город/населённый пункт, улица, корпус, строение, номер дома, офис',
		onFocus: handleBlur,
		name: 'mailingAddress',
		disabled: values.isRegistrationAddressMatchWithMailing,
		onChange: ({ target }) =>
			setFieldValue('mailingAddress', {
				query: target.value,
				selected: null,
			}),
		autocompleteConfig: useAddresscomplete<
			ComCreditclubPartnerCommonDtoV1AddressDto[],
			ComCreditclubPartnerCommonDtoV1AddressDto
		>({
			onManual: mailingAddressModal.setTrue,
			request: {
				url: '/v1/suggestions/address',
				method: 'post',
				data: {
					query: values.mailingAddress.query,
				},
			},
			query: values.mailingAddress.query,
			selected: values.mailingAddress.selected,
			onSelect: (option) =>
				setFieldValue('mailingAddress', {
					query: option.label,
					selected: option,
				}),
		}),
	})

	const mailingAddressModalProps: AddressModalProps = {
		onChange: (value) =>
			setFieldValue('mailingAddress', {
				selected: {
					id: value.mergedAddress,
					label: value.mergedAddress,
					payload: value,
				},
				query: value.mergedAddress,
			}),
		isOpen: mailingAddressModal.value,
		onClose: mailingAddressModal.setFalse,
	}

	const isRegistrationAddressMatchWithMailingProps: CheckboxProps = {
		checked: values.isRegistrationAddressMatchWithMailing,
		onChange: (value) =>
			setValues((p) =>
				mergeDeepRight(p, {
					isRegistrationAddressMatchWithMailing: value,
					mailingAddress: value ? p.registrationAddress : p.mailingAddress,
				})
			),
	}

	const ogrnProps = getInputProps({
		label: 'ОГРН',
		mask: '9'.repeat(13),
		placeholder: '13 цифр',
		value: values.ogrn,
		name: 'ogrn',
		onChange: handleChange,
		errorMessage: errors.ogrn,
		error: 'ogrn' in errors && !!touched.ogrn,
		onFocus: handleBlur,
	})

	const kppProps = getInputProps({
		label: 'КПП',
		mask: '9'.repeat(9),
		name: 'kpp',
		placeholder: '9 цифр',
		value: values.kpp,
		errorMessage: errors.kpp,
		error: 'kpp' in errors && !!touched.kpp,
		onFocus: handleBlur,
		onChange: handleChange,
	})

	const phoneProps = getInputProps({
		label: 'Номер телефона',
		mask: '+7 999 999-99-99',
		value: values.phone,
		name: 'phone',
		placeholder: '+7',
		errorMessage: errors.phone,
		error: 'phone' in errors && !!touched.phone,
		onFocus: handleBlur,
		onChange: handleChange,
	})

	const emailProps = getInputProps({
		label: 'Электронная почта',
		value: values.email,
		name: 'email',
		placeholder: 'Email',
		errorMessage: errors.email,
		error: 'email' in errors && !!touched.email,
		onFocus: handleBlur,
		onChange: handleChange,
	})

	const siteProps = getInputProps({
		label: 'Сайт',
		value: values.site,
		name: 'site',
		placeholder: 'Введите адрес сайта',
		error: 'site' in errors && !!touched.site,
		onFocus: handleBlur,
		onChange: handleChange,
	})

	const nameForClientProps = getInputProps({
		label: 'Наименование для коммуникаций с клиентами',
		value: values.nameForClient,
		placeholder: 'Название компании',
		error: 'nameForClient' in errors && !!touched.nameForClient,
		name: 'nameForClient',
		onFocus: handleBlur,
		onChange: handleChange,
	})

	const representativeFullNameAutocompleteConfig = useAutocomplete({
		categories: [
			{ id: 'empty', label: 'Подсказка по ФИО' },
			{ id: 'manual', label: null },
		],
		renderOption: ({ render, onSelect, option }) =>
			render(
				option.id === 'manual'
					? {
							children: 'Ввести ФИО вручную',
							onSelect: representativeFullNameModal.setTrue,
							styleOverride: {
								':hover': { color: theme.colors.activeHovered },
								color: theme.colors.active,
							},
						}
					: {
							children: option.label,
							onSelect,
						}
			),
		query: values.representativeFullName.query,
		selected: values.representativeFullName.selected,
		getOptionItem: (response) => ({
			id: response.fullName as string,
			label: response.fullName,
			categoryId: 'empty',
			payload: response,
		}),
		onSelect: (option) =>
			setFieldValue('representativeFullName', {
				query: utils.getFullName(option.payload),
				selected: option,
				manual: values.representativeFullName.manual ? null : values.representativeFullName.manual,
			}),
		request: {
			method: 'POST',
			url: '/v1/suggestions/full-name',
			data: {
				query: values.representativeFullName.query,
			},
		},
	})

	const representativeFullNameProps = getInputProps({
		errorMessage: errors.representativeFullName as string,
		error: !!errors.representativeFullName && !!touched.representativeFullName,
		onFocus: handleBlur,
		placeholder: 'Фамилия, имя и отчество',
		name: 'representativeFullName',
		inputContainerStyleOverride: { alignItems: 'center' },
		autocompleteConfig: {
			...representativeFullNameAutocompleteConfig,
			options: [
				...representativeFullNameAutocompleteConfig.options,
				{ id: 'manual', categoryId: 'manual', label: null },
			],
		},
		value: values.representativeFullName.query,
		onChange: ({ target }) =>
			setFieldValue('representativeFullName', {
				query: target.value,
				manual: values.representativeFullName.manual ? null : values.representativeFullName.manual,
				selected: values.representativeFullName.selected
					? null
					: values.representativeFullName.selected,
			}),
		label: 'Фамилия Имя Отчество',
	})

	const representativeFullNameModalProps: FullNameModalProps = {
		isOpen: representativeFullNameModal.value,
		onClose: representativeFullNameModal.setFalse,
		onChange: (value) =>
			setFieldValue('representativeFullName', {
				selected: null,
				query: join([value.surname, value.name, value.patronymic], ' '),
				manual: value,
			}),
	}

	const representativePostProps = getInputProps({
		label: 'Должность',
		name: 'representativePost',
		placeholder: 'Например, менеджер',
		errorMessage: errors.representativePost,
		error: 'representativePost' in errors && touched.representativePost,
		value: values.representativePost,
		onChange: handleChange,
	})

	const mainContactFullNameAutocompleteConfig = useAutocomplete({
		categories: [
			{ id: 'empty', label: 'Подсказка по ФИО' },
			{ id: 'manual', label: null },
		],
		renderOption: ({ render, onSelect, option }) =>
			render(
				option.id === 'manual'
					? {
							children: 'Ввести ФИО вручную',
							onSelect: mainContactFullNameModal.setTrue,
							styleOverride: {
								':hover': { color: theme.colors.activeHovered },
								color: theme.colors.active,
							},
						}
					: {
							children: option.label,
							onSelect,
						}
			),
		query: values.mainContactFullName.query,
		selected: values.mainContactFullName.selected,
		getOptionItem: (response) => ({
			id: response.fullName as string,
			label: response.fullName,
			categoryId: 'empty',
			payload: response,
		}),
		onSelect: (option) =>
			setFieldValue('mainContactFullName', {
				query: utils.getFullName(option.payload),
				selected: option,
				manual: values.mainContactFullName.manual ? null : values.mainContactFullName.manual,
			}),
		request: {
			method: 'POST',
			url: '/v1/suggestions/full-name',
			data: {
				query: values.mainContactFullName.query,
			},
		},
	})

	const mainContactFullNameProps = getInputProps({
		inputContainerStyleOverride: { alignItems: 'center' },
		onFocus: handleBlur,
		name: 'mainContactFullName',
		placeholder: 'Фамилия, имя и отчество',
		error: !!errors.mainContactFullName && !!touched.mainContactFullName,
		autocompleteConfig: {
			...mainContactFullNameAutocompleteConfig,
			options: [
				...mainContactFullNameAutocompleteConfig.options,
				{ id: 'manual', categoryId: 'manual', label: null },
			],
		},
		value: values.mainContactFullName.query,
		onChange: ({ target }) =>
			setFieldValue('mainContactFullName', {
				query: target.value,
				manual: values.mainContactFullName.manual ? null : values.mainContactFullName.manual,
				selected: values.mainContactFullName.selected ? null : values.mainContactFullName.selected,
			}),
		label: 'Фамилия Имя Отчество',
	})

	const mainContactFullNameModalProps: FullNameModalProps = {
		isOpen: mainContactFullNameModal.value,
		onClose: mainContactFullNameModal.setFalse,
		onChange: (value) =>
			setFieldValue('mainContactFullName', {
				selected: null,
				query: join([value.surname, value.name, value.patronymic], ' '),
				manual: value,
			}),
	}

	const mainContactPhoneProps = getInputProps({
		label: 'Номер телефона',
		mask: '+7 999 999-99-99',
		name: 'mainContactPhone',
		onFocus: handleBlur,
		placeholder: '+7',
		errorMessage: errors.mainContactPhone,
		error: 'mainContactPhone' in errors && !!touched.mainContactPhone,
		value: values.mainContactPhone,
		onChange: handleChange,
	})

	const mainContactEmailProps = getInputProps({
		label: 'Электронная почта',
		placeholder: 'Email',
		errorMessage: errors.mainContactEmail,
		error: 'mainContactEmail' in errors && !!touched.mainContactEmail,
		onFocus: handleBlur,
		name: 'mainContactEmail',
		value: values.mainContactEmail,
		onChange: handleChange,
	})

	const bankDetailsNameProps = getInputProps({
		label: 'Наименование банка',
		placeholder: 'Название банка',
		error: !!errors.bankDetailsName && !!touched.bankDetailsName,
		onFocus: handleBlur,
		autocompleteConfig: useAutocomplete({
			query: values.bankDetailsName,
			getOptionItem: (response) => ({
				id: response.bic,
				label: response.name,
				payload: response,
			}),
			onSelect: (option) =>
				setValues((p) =>
					mergeDeepRight(p, {
						bankDetailsName: option.label,
						bankDetailsBic: option.payload.bic ?? values.bankDetailsBic,
						bankDetailsCorrespondentAccount:
							option.payload.correspondentAccount ?? values.bankDetailsCorrespondentAccount,
					})
				),
			request: {
				method: 'POST',
				url: '/v1/suggestions/bank',
				data: {
					query: values.bankDetailsName,
				},
			},
		}),
		value: values.bankDetailsName,
		onChange: ({ target }) => setFieldValue('bankDetailsName', target.value),
	})

	const bankDetailsBicProps = getInputProps({
		label: 'БИК',
		mask: '9'.repeat(9),
		placeholder: '9 цифр',
		name: 'bankDetailsBic',
		onFocus: handleBlur,
		errorMessage: errors.bankDetailsBic,
		error: 'bankDetailsBic' in errors && !!touched.bankDetailsBic,
		value: values.bankDetailsBic,
		onChange: handleChange,
	})

	const bankDetailsCorrespondentAccountProps = getInputProps({
		label: 'К/сч',
		mask: '9'.repeat(20),
		placeholder: 'Номер корреспондентского счета из 20 цифр',
		name: 'bankDetailsCorrespondentAccount',
		errorMessage: errors.bankDetailsCorrespondentAccount,
		onFocus: handleBlur,
		error: 'bankDetailsCorrespondentAccount' in errors && !!touched.bankDetailsCorrespondentAccount,
		value: values.bankDetailsCorrespondentAccount,
		onChange: handleChange,
	})

	const bankDetailsCheckingAccountProps = getInputProps({
		label: 'Р/сч',
		mask: '9'.repeat(20),
		name: 'bankDetailsCheckingAccount',
		placeholder: 'Номер расчетного счета состоит из 20 цифр',
		errorMessage: errors.bankDetailsCheckingAccount,
		onFocus: handleBlur,
		error: 'bankDetailsCheckingAccount' in errors && !!touched.bankDetailsCheckingAccount,
		value: values.bankDetailsCheckingAccount,
		onChange: handleChange,
	})

	const controls: CompanyControlsProps = {
		isLoading: status === 'pending',
		resetForm,
	}

	return (
		<FadeIn>
			<form autoComplete='off' onSubmit={handleSubmit}>
				<AddressModal {...mailingAddressModalProps} />
				<AddressModal {...registrationAddressModalProps} />
				<FullNameModal {...representativeFullNameModalProps} />
				<FullNameModal {...mainContactFullNameModalProps} />
				<Grid.Section>
					<Grid.Row sizes='1fr'>
						<Field edit={edit} {...pick(['label', 'value'], fullNameProps)}>
							<Input {...fullNameProps} />
						</Field>
					</Grid.Row>
					<Grid.Row>
						<Field edit={edit} {...pick(['label', 'value'], taxIdProps)}>
							<Input {...taxIdProps} />
						</Field>
					</Grid.Row>
					<Grid.Row sizes='1fr'>
						<Field edit={edit} {...pick(['label', 'value'], registrationAddressProps)}>
							<Input {...registrationAddressProps} />
						</Field>
					</Grid.Row>
					<Grid.Row sizes='1fr'>
						<Field edit={edit} {...pick(['label', 'value'], mailingAddressProps)}>
							<Input {...mailingAddressProps} />
						</Field>
					</Grid.Row>
					{edit && (
						<Grid.Row>
							<Checkbox {...isRegistrationAddressMatchWithMailingProps}>
								Совпадает с адресом регистрации
							</Checkbox>
						</Grid.Row>
					)}
					<Grid.Row>
						<Field edit={edit} {...pick(['label', 'value'], ogrnProps)}>
							<Input {...ogrnProps} />
						</Field>
						<Field edit={edit} {...pick(['label', 'value'], kppProps)}>
							<Input {...kppProps} />
						</Field>
					</Grid.Row>
					<Grid.Row>
						<Field edit={edit} {...pick(['label', 'value'], phoneProps)}>
							<Input {...phoneProps} />
						</Field>
						<Field edit={edit} {...pick(['label', 'value'], emailProps)}>
							<Input {...emailProps} />
						</Field>
					</Grid.Row>
					<Grid.Row>
						<Field edit={edit} {...pick(['label', 'value'], siteProps)}>
							<Input {...siteProps} />
						</Field>
						<Field edit={edit} {...pick(['label', 'value'], nameForClientProps)}>
							<Input {...nameForClientProps} />
						</Field>
					</Grid.Row>
					<Grid.Row sizes='auto 1fr' gap='xs' style={{ alignItems: 'center' }}>
						<Text font='h5' weight='bold'>
							Представитель
						</Text>
						<Hint
							config={{ placement: 'right' }}
							element={
								<Text font='body-2' weight='normal'>
									Лицо уполномоченное действовать от организации. Это может быть директор,
									генеральный директор или сотрудник по доверенности
								</Text>
							}
						/>
					</Grid.Row>
					<Grid.Row>
						<Field edit={edit} {...pick(['label', 'value'], representativeFullNameProps)}>
							<Input {...representativeFullNameProps} />
						</Field>
						<Field edit={edit} {...pick(['label', 'value'], representativePostProps)}>
							<Input {...representativePostProps} />
						</Field>
					</Grid.Row>
					<Text font='h5' weight='bold'>
						Контактное лицо
					</Text>
					<Grid.Row>
						<Field edit={edit} {...pick(['label', 'value'], mainContactFullNameProps)}>
							<Input {...mainContactFullNameProps} />
						</Field>
						<Field edit={edit} {...pick(['label', 'value'], mainContactPhoneProps)}>
							<Input {...mainContactPhoneProps} />
						</Field>
					</Grid.Row>
					<Grid.Row>
						<Field edit={edit} {...pick(['label', 'value'], mainContactEmailProps)}>
							<Input {...mainContactEmailProps} />
						</Field>
					</Grid.Row>
					<Text font='h5' weight='bold'>
						Банковские реквизиты
					</Text>
					<Grid.Row>
						<Field edit={edit} {...pick(['label', 'value'], bankDetailsNameProps)}>
							<Input {...bankDetailsNameProps} />
						</Field>
						<Field edit={edit} {...pick(['label', 'value'], bankDetailsBicProps)}>
							<Input {...bankDetailsBicProps} />
						</Field>
					</Grid.Row>
					<Grid.Row>
						<Field edit={edit} {...pick(['label', 'value'], bankDetailsCorrespondentAccountProps)}>
							<Input {...bankDetailsCorrespondentAccountProps} />
						</Field>
						<Field edit={edit} {...pick(['label', 'value'], bankDetailsCheckingAccountProps)}>
							<Input {...bankDetailsCheckingAccountProps} />
						</Field>
					</Grid.Row>
					<CompanyControls {...controls} />
				</Grid.Section>
			</form>
		</FadeIn>
	)
}
