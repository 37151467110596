import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { organizationsApi } from 'api/organizations'
import { profileApi } from 'api/profile'
import {
	ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoRoleEnum,
	ComCreditclubPartnerLkDtoV1OrganizationPartnerOrganizationRefDtoLegalTypeEnum,
	ComCreditclubPartnerLkDtoV1OrganizationPartnerProfileDtoVerificationStatusEnum,
} from 'dto'
import { useAppCtx } from 'providers/features/useAppCtx'
import { Nullable } from 'ts-toolbelt/out/Union/Nullable'

import { BadgeProps } from 'components/common/Badge'
import { useControls } from 'components/common/Controls/useControls'

export const companyFormCtx = createContext<{
	controls: ReturnType<typeof useControls>
	isUserHasAccess: boolean
	isVerified: boolean
	isNotStarted: boolean
	isFailed: boolean
}>(null!)

export const useCompanyFormCtx = () => useContext(companyFormCtx)

type LegalType =
	keyof typeof ComCreditclubPartnerLkDtoV1OrganizationPartnerOrganizationRefDtoLegalTypeEnum

const getTitleBadgePropsByStatus = (status?: string): BadgeProps | undefined => {
	switch (status) {
		case ComCreditclubPartnerLkDtoV1OrganizationPartnerProfileDtoVerificationStatusEnum.VERIFIED:
			return {
				variantColor: 'green',
				children: 'Верифицирован',
			}
		case ComCreditclubPartnerLkDtoV1OrganizationPartnerProfileDtoVerificationStatusEnum.VERIFYING:
			return {
				variantColor: 'orange',
				children: 'На проверке',
			}
		case ComCreditclubPartnerLkDtoV1OrganizationPartnerProfileDtoVerificationStatusEnum.FAILED:
			return {
				variantColor: 'red',
				children: 'Проверка не пройдена',
			}
		default:
			return undefined
	}
}

const getHintBadgePropsByStatus = ({
	status,
	edit,
	activeTab,
}: {
	status?: string
	edit: boolean
	activeTab?: Nullable<LegalType>
}): BadgeProps | undefined => {
	if (
		status ===
			ComCreditclubPartnerLkDtoV1OrganizationPartnerProfileDtoVerificationStatusEnum.FAILED &&
		!edit
	) {
		return {
			icon: 'warning',
			font: 'body-2',
			variantColor: 'red',
			children:
				'Аккаунт не верифицирован. Проверьте правильность введённых данных и внесите изменения.',
		}
	} else if (edit && activeTab !== 'PERSON') {
		return {
			icon: 'pencil',
			font: 'body-2',
			variantColor: 'yellow',
			children: 'Начните вводить название компании и мы заполним анкету автоматически.',
		}
	}

	return undefined
}

export const useCompanyForm = () => {
	const { profile } = useAppCtx()
	const controls = useControls({
		onCancel: useCallback(() => setActiveTab(profile?.legalType), [profile?.legalType]),
	})
	const { data, status, isFetched } = organizationsApi.useGetOrganization({
		enabled: !!profile.selectedOrganizationId && !controls.edit,
		variables: { id: profile.selectedOrganizationId! },
	})
	const { data: profileData } = profileApi.useGetProfile({
		enabled: !!profile.selectedOrganizationId,
		variables: { orgId: profile.selectedOrganizationId! },
	})
	const [isInitialized, setIsInitialized] = useState(false)
	const [activeTab, setActiveTab] = useState<Nullable<LegalType>>(
		profile.legalType ?? 'ORGANIZATION'
	)

	useEffect(() => {
		profile.legalType && setActiveTab(profile.legalType)
	}, [profile.legalType])

	const handleSetActiveTab = useCallback((value: LegalType) => () => setActiveTab(value), [])

	const hasProfile = !!data?.profile

	const ctx = useMemo(
		() =>
			({
				isVerified:
					data?.profile?.verificationStatus ===
					ComCreditclubPartnerLkDtoV1OrganizationPartnerProfileDtoVerificationStatusEnum.VERIFIED,
				isUserHasAccess:
					profileData?.role ===
					ComCreditclubPartnerLkDtoV1OrganizationPartnerEmployeeDtoRoleEnum.OWNER,
				isNotStarted:
					!hasProfile ||
					data?.profile?.verificationStatus ===
						ComCreditclubPartnerLkDtoV1OrganizationPartnerProfileDtoVerificationStatusEnum.NOTSTARTED,
				isFailed:
					ComCreditclubPartnerLkDtoV1OrganizationPartnerProfileDtoVerificationStatusEnum.FAILED ===
					data?.profile?.verificationStatus,
				isVerifying:
					ComCreditclubPartnerLkDtoV1OrganizationPartnerProfileDtoVerificationStatusEnum.VERIFYING ===
					data?.profile?.verificationStatus,
				controls,
			}) as const,
		[controls, data?.profile?.verificationStatus, hasProfile, profileData?.role]
	)

	const titleBadgeProps = getTitleBadgePropsByStatus(data?.profile?.verificationStatus)
	const hintBadgeProps = getHintBadgePropsByStatus({
		status: data?.profile?.verificationStatus,
		edit: controls.edit,
		activeTab,
	})

	useEffect(() => {
		if (isInitialized || !isFetched) return

		if (
			[!ctx.isUserHasAccess && controls.edit, !ctx.isVerified && !ctx.isUserHasAccess].some(Boolean)
		) {
			controls.setEdit(false)
			return
		}

		if (ctx.isNotStarted && !controls.edit) controls.setEdit(true)
		if (ctx.isFailed && controls.edit) controls.setEdit(false)
		if (ctx.isVerified && controls.edit) controls.setEdit(false)
		if (ctx.isVerifying && controls.edit) controls.setEdit(false)
		setIsInitialized(true)
	}, [
		controls,
		isFetched,
		isInitialized,
		ctx.isUserHasAccess,
		ctx.isNotStarted,
		ctx.isVerified,
		ctx.isVerifying,
		ctx.isFailed,
	])

	return {
		organizationStatus: status,
		ctx,
		titleBadgeProps,
		hintBadgeProps,
		activeTab,
		legalType: data?.profile?.legalType,
		handleSetActiveTab,
	}
}
